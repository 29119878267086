import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    web_sales_title:{eng:'KAPETAN LUKA - KRILO', cro:'KAPETAN LUKA - KRILO'},
    search_title_empty:{eng:'Where do you want to travel?', cro:'Gdje želite putovati?'},
    search_title_additional:{eng:'Do you need another tickets?', cro:'Trebate još karata?'},
    travel_from:{eng:'Travel from', cro: 'Od'},   
    travel_to:{eng:'Travel to', cro: 'Do'},   
    travel_date:{eng:'Date', cro: 'Datum'}, 
    selectText:{eng:'Please select a departure', cro:'Molimo odaberite polazak'},
    departure:{eng: 'Departure', cro:'Polazak'},
    arrival:{eng: 'Arrival', cro:'Dolazak'},
    seats:{eng: 'Available', cro:'Slobodno'},
    price:{eng: 'Price', cro:'Cijena'},

    one_way:{eng: 'One Way', cro:'Jednosmjerno'},
    round_trip:{eng: 'Round trip', cro:'Povratno'},
    select_trips:{eng:'Book your trip', cro:'Odaberi putovanje'},
    ticket_select: {eng:'Who will travel?', cro:'Tko će putovati?'},
    ticket_type_name: {eng:'Ticket type', cro:'Vrsta karte'},
    ticket_price: {eng:'Ticket price', cro:'Cijena karte'},
    ticket_quantity: {eng:'Quantity', cro:'Količina'},
    ticket_select_quit: {eng:'CANCEL', cro:'ODUSTANI'},
    add_tickets:{eng:'ADD TICKETS', cro:'Odaberi karte'},
    summary_title:{eng:'Your trip', cro:'Odabrano putovanje'},
    summary_subtitle:{eng:'(you can add more trips to your order before making a payment)', cro:'(možete dodati još putovanja prije završetka kupnje)'},
    summary_time:{eng:'Reservation time: ', cro:'Vrijeme rezervacije: '},
    summary_departure:{eng:'departure', cro:'polazak'},
    summary_ticket_type:{eng:'Ticket type', cro:'Vrsta karte'},
    summary_quantity:{eng:'Quantity', cro:'Količina'},
    summary_price:{eng:'Price', cro:'Cijena'},
    summary_amount:{eng:'Amount', cro:'Iznos'},
    summary_pay:{eng:'PAY ', cro:'PLATI '},
    summary_remove_ticket: {eng:'REMOVE', cro:'UKLONI'},
    summary_buyer_info:{eng:'Your data is used exclusively for the delivery of invoices and tickets and, if necessary, as a contact if there is an important change related to your trip', cro:'Vaši podaci nam služe isključivo za dostavu računa i karata te u slučaju potrebe kao kontak ako dođe to važne promijene vezane za vaše putovanje'},
    summary_buyer_title:{eng:'Contact details', cro:'Podaci o kupcu '},
    summary_buyer_name:{eng:'Buyer name ', cro:'Ime kupca'},
    summary_buyer_email:{eng:'Buyer email ', cro:'Email kupca'},
    summary_buyer_tel:{eng:'Buyer tel ', cro:'Tel kupca'},
    summary_buyer_company_invoice:{eng:'Commercial Invoice', cro:'R1'},
    summary_buyer_company_name:{eng:'Company name', cro:'Naziv tvrtke'},
    summary_buyer_company_address:{eng:'Company address ', cro:'Adresa tvrtke'},
    summary_buyer_company_vat_id:{eng:'Company VAT ID ', cro:'OIB/VAT ID tvrtke'},
    summary_buyer_company_country:{eng:'Country', cro:'Država'},
    summary_checkbox_term_first:{eng:'I agree to', cro:'Prihvaćam '},
    summary_checkbox_term_general:{eng:' General buying terms, ', cro:'Opće uvjete kupnje, '},
    summary_checkbox_term_payment:{eng:'Payment and Privacy statement', cro:'Izjavu o sigurnosti plaćanja i zaštiti podataka'},
    download_title:{eng:'KAPETAN LUKA - KRILO' , cro:'KAPETAN LUKA - KRILO'},
    download_invoice:{eng:'Download INVOICE' , cro:'Preuzimanje RAČUNA'},
    download_tickets:{eng:'Download TICKETS' , cro:'Preuzimanje KARATA'},
    download_back_to_booking:{eng:'Back to BOOKING' , cro:'POvratak na kupovinu'},

    home_title:{eng:"Home", cro:"Početna"},
    refund_title:{eng:"Refund information", cro:"Upute za povrat novca"},
    faq_title:{eng:"FAQ", cro:"Često postavljana pitanja"},
    payment_title:{eng:"Payment and privacy statement", cro:"Izjava o sigurnosti plaćanja"},
    terms_title:{eng:"Terms and conditions", cro:"Opći uvjeti kupovine"},
    
    home_link:{eng:"https://www.krilo.hr/en/", cro:"https://www.krilo.hr/"},
    refund_link:{eng:"https://www.krilo.hr/en/about-us/refund-information/", cro:"https://www.krilo.hr/o-nama/upute-za-povrat-novca/"},
    faq_link:{eng:"https://www.krilo.hr/en/about-us/faq/", cro:"https://www.krilo.hr/o-nama/cesto-postavljana-pitanja/"},
    payment_link:{eng:"https://www.krilo.hr/en/about-us/payment-and-privacy-statement/", cro:"https://www.krilo.hr/o-nama/izjava-o-sigurnosti-placanja/"},
    terms_link:{eng:"https://www.krilo.hr/en/about-us/terms-and-conditions/", cro:"https://www.krilo.hr/o-nama/opci-uvjeti-kupovine/"},
    
}

const stringsSlice = createSlice({
    name: 'strings',
    initialState,
    reducers:{}
})

export const allStrings = (state) => state.strings

export default stringsSlice.reducer