import { createSlice, } from "@reduxjs/toolkit";

const initialState = {
    language: 'cro',
    dates: {
        date_format: { eng: 'YYYY-MM-DD', cro: 'DD-MM-YYYY' },
        datetime_format: { eng: 'YYYY-MM-DD HH:mm', cro: 'DD-MM-YYYY HH:mm' },
        notifications: {

        }
    },
}


const appSetupSlice = createSlice({
    name: 'appSetup',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload
        }
    },
})

export const { changeLanguage } = appSetupSlice.actions

export const allAppSetup = (state) => state.appSetup;


export default appSetupSlice.reducer