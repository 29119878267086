import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { HeaderSmall, HeaderTicketNum } from "./Headers";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTripData,
  setSelectedTickets,
  setSelectedTripData,
  setStatus,
  setTickets,
  setTicketsCounter,
  updateTicketsCounter,
  webSalesStatusesData,
  webSalesTripData,
} from "../web_salesSlice";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { allStrings } from "../stringsSlice";
import { allAppSetup } from "../appSetupSlice";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const SelectTicketsComponent = () => {
  const dispatch = useDispatch();
  const strings = useSelector(allStrings);
  const appSetup = useSelector(allAppSetup);
  const tripData = useSelector(webSalesTripData);
  const statuses = useSelector(webSalesStatusesData)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    dispatch(setStatus({ path: "selectTicketType", value: false }));
    dispatch(resetTripData())
  }

  const open = Boolean(anchorEl);

  let categoryPricesToShow = [];
  const priceForTrips = () => {
      for (const categoryPriceNew of tripData.selected_trip.prices) {
        const categoryPriceNewFinal = {
          firstPrice: Number(categoryPriceNew.price),
          firstPriceVatBase: Number(categoryPriceNew.vat_base),
          firstPriceVat: Number(categoryPriceNew.vat),
          firstPriceHarborTax: Number(categoryPriceNew.harbor_tax),
          secondPrice: 0,
          secondPriceVatBase: 0,
          secondPriceVat: 0,
          secondPriceHarborTax: 0,
          priceId: categoryPriceNew.id,
          ticket_type_name: categoryPriceNew.ticket_type_name,
          ticket_type_uuid: categoryPriceNew.ticket_type_uuid,
          description: categoryPriceNew.description,
          price: Number(categoryPriceNew.price),
        };
        categoryPricesToShow = [...categoryPricesToShow, categoryPriceNewFinal];
      }
     };
  priceForTrips();

  const handleAddTicket = (e, price) => {
    const value = e.target.value;
    const counter = {
      id: price.priceId,
      data: price,
      quantity: value,
    };
    dispatch(setTicketsCounter({ path: price.priceId, value: counter }));
  };

  const handleAddTickets = (e) => {
    e.preventDefault();
    let tickets = [];
    let num = 0;
    for (const newTicketData of tripData.ticketsCounter) {
      if (newTicketData) {
        if (newTicketData.quantity > 0) {
          let ticketsCodes = [];
          for (let i = 0; i < newTicketData.quantity; i++) {
            const newCode = {
              uuid: uuid(),
              code: uuid(),
            };
            ticketsCodes = [...ticketsCodes, newCode];
          }
          num++;
          const newTicket = {
            id: num,
            sales_route_uuid: tripData.selected_trip.uuid,
            line_code: tripData.selected_trip.line_code,
            line_name: tripData.selected_trip.line_name,
            departure: tripData.selected_trip.departure,
            departure_harbor_id: tripData.selected_trip.departure_harbor_id,
            departure_harbor_name: tripData.selected_trip.departure_harbor_name,
            arrival: tripData.selected_trip.arrival,
            arrival_harbor_id: tripData.selected_trip.arrival_harbor_id,
            arrival_harbor_name: tripData.selected_trip.arrival_harbor_name,
            ticket_type_name: newTicketData.data.ticket_type_name,
            ticket_type_id: newTicketData.data.ticket_type_id,
            ticket_type_uuid: newTicketData.data.ticket_type_uuid,
            ticket_group_uuid: uuid(),
            single_price: newTicketData.data.price,
            total_price: newTicketData.data.price * newTicketData.quantity,
            total_vat_base:
              newTicketData.data.vat_base * newTicketData.quantity,
            total_vat: newTicketData.data.vat * newTicketData.quantity,
            total_harbor_tax:
              newTicketData.data.harbor_tax * newTicketData.quantity,
            quantity: newTicketData.quantity,
            tickets: ticketsCodes,
          };
          dispatch(setTickets({ value: newTicket }));
          //tickets = [...tickets, newTicket];

          if (tripData.searchData.return_trip) {
            ticketsCodes = [];
            for (let i = 0; i < newTicketData.quantity; i++) {
              const newCode = {
                uuid: uuid(),
                code: uuid(),
              };
              ticketsCodes = [...ticketsCodes, newCode];
            }
            num++;
            const newReturnTicket = {
              id: num,
              sales_route_uuid: tripData.selected_return_trip.uuid,
              line_code: tripData.selected_return_trip.line_code,
              line_name: tripData.selected_return_trip.line_name,
              departure: tripData.selected_return_trip.departure,
              departure_harbor_id:
                tripData.selected_return_trip.departure_harbor_id,
              departure_harbor_name:
                tripData.selected_return_trip.departure_harbor_name,
              arrival: tripData.selected_return_trip.arrival,
              arrival_harbor_id:
                tripData.selected_return_trip.arrival_harbor_id,
              arrival_harbor_name:
                tripData.selected_return_trip.arrival_harbor_name,
              passengers_type: newTicketData.data.passengers_type,
              passengers_type_uuid: newTicketData.data.passengers_type_uuid,
              single_price: newTicketData.data.price,
              total_price: newTicketData.data.price * newTicketData.quantity,
              total_vat_base:
                newTicketData.data.vat_base * newTicketData.quantity,
              total_vat: newTicketData.data.vat * newTicketData.quantity,
              total_harbor_tax:
                newTicketData.data.harbor_tax * newTicketData.quantity,
              quantity: Number(newTicketData.quantity),
              tickets: ticketsCodes,
            };
            dispatch(setTickets({ value: newReturnTicket }));
            //tickets = [...tickets, newReturnTicket];
          }
        }
      }
    }

    dispatch(setStatus({ path: "selectTicketType", value: false }));
    const initData = {
      searchData: {
        travel_date: "",
        return_travel_date: "",
        return_trip: false,
      },
      selectedData: {
        selected_trip: {
          id: 0,
        },
      },
      selected_trip: {
        id: 0,
      },
      selected_return_trip: {
        id: 0,
      },
      ticketsCounter: [],
    };
    dispatch(resetTripData());
  };

  const [ticketCounter, setTicketCounter] = useState([
    {
      id: 1,
      quantity: 15,
    },
    {
      id: 2,
      quantity: 25,
    },
    {
      id: 3,
      quantity: 35,
    },
  ]);

  const addInitialState = () => {
    let typeToAdd = [];
    for (const newType of categoryPricesToShow) {
      const counter = {
        id: newType.priceId,
        data: newType,
        quantity: 0,
      };
      typeToAdd = [...typeToAdd, counter];
    }
    dispatch(setTicketsCounter({ value: typeToAdd }));
  };

  useEffect(() => {
    addInitialState();
  }, []);

  const handlePlus = (e, price) => {
    const quantity = tripData.ticketsCounter.find(
      (number) => number.data.ticket_type_uuid && number.data.ticket_type_uuid === price.ticket_type_uuid
    );
    const value = quantity.quantity + 1;
    const counter = {
      id: price.priceId,
      data: price,
      quantity: value,
    };
    dispatch(updateTicketsCounter({ path: price.priceId, value: counter }));
  };

  const handleMinus = (e, price) => {
    const quantity = tripData.ticketsCounter.find(
      (number) => number.data.ticket_type_uuid && number.data.ticket_type_uuid === price.ticket_type_uuid
    );
    let value = quantity.quantity - 1;

    if (value < 1) {
      value = 0;
    }
    const counter = {
      id: price.priceId,
      data: price,
      quantity: value,
    };
    dispatch(updateTicketsCounter({ path: price.priceId, value: counter }));
  };

  const showQuantity = (id) => {
    if (tripData.ticketsCounter.length < 1) {
      return 0;
    } else {
      const quantity = tripData.ticketsCounter.find(
        (number) => number.data.ticket_type_uuid && number.data.ticket_type_uuid === id
      );
      return quantity.quantity;
    }
  };

  const canSelectTicket = () => {
    const findQuantity = tripData.ticketsCounter.find((number) => number.quantity !== 0);
    const canSelect = [findQuantity].every(Boolean);
    dispatch(setStatus({ path: "canSelectTickets", value: canSelect }));
  }

  useEffect(() => {
    canSelectTicket()
  }, [tripData.ticketsCounter])

  return (
    <Grid>
      <Grid item xs={12}>
        <Grid item container direction="row" mb={2}>
          <Grid item xs={12}>
            <Box>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button color="error" onClick={handleCancel}>{strings.ticket_select_quit[appSetup.language]}</Button>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <HeaderTicketNum
                  title={strings.ticket_select[appSetup.language]}
                />
              </Box>
              <Box>
                <form >
                  <Grid container direction="column">
                    <Grid>
                      <TableContainer
                        component={Paper}
                        sx={{
                          my: 2,
                        }}
                      >
                        <Table size="small" aria-label="a dense table">

                          <TableBody>
                            {categoryPricesToShow.map((price) => (
                              <>
                                <TableRow
                                  className="select"
                                  key={price.priceId}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    border: 0
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    colSpan={4}
                                    sx={{ border: "none" }}
                                  >
                                    {price.ticket_type_name}{" "}
                                    <Tooltip title={price.description}>
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{ border: "none" }}
                                >
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    colSpan={4}
                                  >
                                    <Stack
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Box>
                                        <Typography>
                                          {price.price.toFixed(2)} EUR
                                        </Typography>

                                      </Box>
                                      <Box>

                                        <ToggleButtonGroup>
                                          <ToggleButton
                                            value=''
                                            sx={{
                                              height: 40,
                                              border: 'none'
                                            }}
                                          >
                                            <IconButton
                                              color="error"
                                              aria-label="ticekt minus"
                                              onClick={(e) => handleMinus(e, price)}
                                            >
                                              <RemoveIcon />
                                            </IconButton>
                                          </ToggleButton>
                                          <ToggleButton
                                          value=''
                                            sx={{
                                              height: 40,
                                              border: 'none'
                                            }}
                                          >
                                            <Typography
                                              variant="outlined"
                                              align="right"
                                              fontWeight='bold'
                                              sx={{
                                                width: 40,
                                                fontSize: 16
                                              }}
                                            //onChange={(e) => handleAddTicket(e, price)}
                                            >
                                              {showQuantity(price.ticket_type_uuid)}
                                            </Typography>
                                          </ToggleButton>
                                          <ToggleButton
                                          value=''
                                            sx={{
                                              height: 40,
                                              border: 'none'
                                            }}
                                          >
                                            <IconButton
                                              color="success"
                                              aria-label="ticekt plus"
                                              onClick={(e) => handlePlus(e, price)}
                                            >
                                              <AddIcon />
                                            </IconButton>
                                          </ToggleButton>
                                        </ToggleButtonGroup>
                                      </Box>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!statuses.canSelectTickets}
                        onClick={handleAddTickets}
                        sx={{
                          height: 60,
                        }}
                      >
                        <h2>{strings.add_tickets[appSetup.language]}</h2>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectTicketsComponent;
