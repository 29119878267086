import axios from "axios";
import { SHA512, enc } from 'crypto-js';
import { useSelector } from "react-redux";
import { allData } from "./bookingSlice";
import { webSalesTicketsData } from "../web_sales/web_salesSlice";

export const MonriPaymnet = async (value) => {
  console.log(value)
  const url = "https://ipgtest.monri.com/v2/form";
  const dataa ={
    merchantKey : `key-51ca0d72634b7c0d357455ad0c0ba29b`,
    order_number: value.order_number,
    amount: value.amount,
    currency: 'EUR',
  }
  
  const key = SHA512(dataa.merchantKey + dataa.order_number + dataa.amount + dataa.currency).toString(enc.Hex);

  console.log(key)

  const order = {
    order_info: value.order_number,
    order_number: dataa.order_number,
    amount: dataa.amount,
    currency: dataa.currency,
    language: 'en',
    transaction_type:'purchase',
    authenticity_token: `d9f9d7aa4c0701a64be0d1225d8d0edd6e8c1f4d`,
    digest: key,
    success_url_override:' https://booking.tech4beez.com/download'
  }

  try {
    const response = await axios.post(url, order, {
        headers:{
      'Content-Type':'application/json',
      'Accept':'application/json'
    }}).then ((response) => { window.open(response.data.payment_url, '_self')});
    
  } catch (error) {
    console.error(error);
  }
   
    

};
