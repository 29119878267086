import { Box, Button, Grid, Paper, ThemeProvider } from "@mui/material"
import { theme } from "../../theme";

import {url} from "../../config/config"

import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { saveAs } from 'file-saver'
import Navbar from "./components/Navbar";

import background1 from "../../img/boat1111.jpeg";
import { useSelector } from "react-redux";
import { allStrings } from "./stringsSlice";
import { allAppSetup } from "./appSetupSlice";


export default function Download() {
    const strings = useSelector(allStrings)
    const appSetup = useSelector(allAppSetup)
    const [searchParams, setSearchParams] = useSearchParams();
    const order = searchParams.get('order_number');

    const createPDF = async() =>{
        await axios.get(url+'/create_invoice_pdf/' + order )
            .then((res) => {
                console.log(res)
            })
    }

    const downloadPDF = async () =>{
        await axios.get(url +'/download_invoice_pdf/' + order, { responseType: 'blob' })
            .then((res) => {
                console.log(res)
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
                saveAs(pdfBlob, 'Invoice.pdf')  //to save we use file saver;
            })
    }

    const createTicketsPDF = async() =>{
        await axios.get(url+'/create_tickets_pdf/' + order )
            .then((res) => {
                console.log(res)
            })
    }

    const downloadTicketsPDF = async () =>{
        await axios.get(url +'/download_tickets_pdf/' + order, { responseType: 'blob' })
            .then((res) => {
                console.log(res)
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
                saveAs(pdfBlob, 'Tickets.pdf')  //to save we use file saver;
            })
    }

    const handleInvoiceDownload = async () => {
        //await createPDF()
        await downloadPDF()
    }

    const handleTicketDownload = async () => {
        //await createTicketsPDF()
        await downloadTicketsPDF()
    }

   
    return (
        <>
        <ThemeProvider theme={theme}>
            <Grid container
            
                
                
            >
                <Box
                alignItems="flex-end"
                fullwidth = 'true'
                sx={{
                    width:"100%",
                    height:"100%",
                    backgroundImage: `url(${background1})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",      
                }}
                >
                <Grid item xs={12}>
                    <Navbar title={strings.download_title[appSetup.language]}/>
                </Grid>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "100vh" }}
            > 
            <Grid item
                xs={11} sm={8} lg={6} xl={4}
            >

                <Paper
                    elevation={2}
                    fullwidth
                    sx={{
                        padding: 5
                    }}
                >
                    <Grid item xs={12}>
                        

                        <Button
                            variant="contained"
                            onClick={handleInvoiceDownload}
                            size="large"
                            sx={{
                                width: '100%',
                                height: 75,
                                mb: 3
                            }}
                        >
                            {strings.download_invoice[appSetup.language]}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handleTicketDownload}
                            size="large"
                            sx={{
                                width: '100%',
                                height: 75,
                                mb: 3
                            }}
                        >
                            {strings.download_tickets[appSetup.language]}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            href="https://booking.tech4beez.com"
                            size="large"
                            sx={{
                                width: '100%',
                                height: 75,
                                mb: 3
                            }}
                        >
                            {strings.download_back_to_booking[appSetup.language]}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
            </Grid>
            </Box>
            </Grid>
        </ThemeProvider>

        </>
    )
}