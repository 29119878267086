
import './App.css';
import { Route, Routes } from 'react-router-dom';

import Booking from './pages/sales/booking';
import WebSales from './pages/web_sales/web_sales';

import { monriPaymnet } from './pages/sales/monri';
import { useDispatch } from 'react-redux';
import { getDataThunk } from './pages/web_sales/web_salesSlice';
import { useEffect } from 'react';
import InvoiceTamplate from './pages/web_sales/components/invoiceTamplate';
import Download from './pages/web_sales/download';


function App() {

  const dispatch = useDispatch();
  const FatchData = async () => {
    const data = await dispatch(getDataThunk({ path: "web_data" })).unwrap();
  };

  useEffect(() => {
    FatchData();
  }, []);

  return (
     <Routes>
      <Route path='/' element={<WebSales />}/>
      <Route path='/download' element={<Download />}/>
     </Routes>
  );
}

export default App;
