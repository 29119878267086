import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import {
  Autocomplete,
  ButtonGroup,
  Button,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Header, HeaderTrip } from "./Header";
import { useSelector } from "react-redux";
import { allData } from "../pages/sales/bookingSlice";

const options = {};

const d1 = new Date();
const d2 = new Date(d1);
const d3 = new Date(d1);
const d4 = new Date(d1);
const d5 = new Date(d1);
d2.setDate(d1.getDate() + 1);
d2.toLocaleDateString();
d3.setDate(d1.getDate() + 2);
d3.toLocaleDateString();
d4.setDate(d1.getDate() + 3);
d4.toLocaleDateString();
d5.setDate(d1.getDate() + 4);
d5.toLocaleDateString();

const d2x = d2.getDate() + "." + (d2.getMonth() + 1);
const d3x = d3.getDate() + "." + (d3.getMonth() + 1);
const d4x = d4.getDate() + "." + (d4.getMonth() + 1);
const d5x = d5.getDate() + "." + (d5.getMonth() + 1);

const BookingSearch = ({
  tripData,
  setTripData,
  setTripExist,
  setToConfirm,
  setReturnTrip,
  isReturnTrip,
  canSearch,
  handleStartSearch,
  handleOneWayTrip,
  handleReturnTrip,
}) => {
  const data = useSelector(allData);
  const harbors = data.data.harbors;
  const style = {
    mt: 3,
    bgcolor: "background.paper",
  };

  let fromTo =  '';
  useEffect(()=>{
        console.log(tripData.travelFrom.harbor_name)
        console.log(tripData.travelTo.harbor_name)
        if(tripData.travelFrom.harbor_name && tripData.travelTo.harbor_name){
          console.log('tu smo')
            fromTo = tripData.travelFrom.harbor_name + " -> " + tripData.travelTo.harbor_name;
            console.log(fromTo)
        }
  },[
    
  ])
   
  console.log(fromTo)

  const handleDateD2 = () => {
    setTripData({ ...tripData, travelDate: d2 });
  };
  const handleDateD3 = () => {
    setTripData({ ...tripData, travelDate: d3 });
  };
  const handleDateD4 = () => {
    setTripData({ ...tripData, travelDate: d4 });
  };
  const handleDateD5 = () => {
    setTripData({ ...tripData, travelDate: d5 });
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Grid item>
              <Header subtitle="Where do you want to travel?" />
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  disablePortal
                  id="travel_from"
                  options={harbors}
                  fullwidth="true"
                  //value={tripData.travelFrom}
                  renderInput={(params) => (
                    <TextField {...params} label="Travel from" />
                  )}
                  onChange={(event, newValue) => {
                    setTripData({ ...tripData, travelFrom: newValue });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disablePortal
                  id="travel_to"
                  options={harbors}
                  fullwidth="true"
                  //value={tripData.travelTo}
                  renderInput={(params) => (
                    <TextField {...params} label="Travel to" />
                  )}
                  onChange={(event, newValue) => {
                    setTripData({ ...tripData, travelTo: newValue });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    fullWidth
                    sx={{
                      height: 60,
                    }}
                  >
                    <Button
                      onClick={handleOneWayTrip}
                      color={isReturnTrip ? "primary" : "info"}
                    >
                      One way
                    </Button>
                    
                    <Button
                      onClick={handleReturnTrip}
                      color={isReturnTrip ? "info" : "primary"}
                      disabled={data.trips ? false :  true}
                    >
                      Round trip
                    </Button>
                      
                    
                  </ButtonGroup>
                </Grid>
              <Grid item xs={12}>
                {fromTo ?
                <HeaderTrip subtitle={fromTo} />
                :''}
              </Grid>
              <Grid item container direction="row" spacing={1} mb={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    fullWidth
                    sx={{
                      height: 55,
                    }}
                  >
                    <Button onClick={handleDateD2}>
                      <Box>{d2x}</Box>
                    </Button>
                    <Button onClick={handleDateD3}>
                      <Box>{d3x}</Box>
                    </Button>
                    <Button onClick={handleDateD4}>
                      <Box>{d4x}</Box>
                    </Button>
                    <Button onClick={handleDateD5}>
                      <Box>{d5x}</Box>
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="Travel date"
                          inputFormat="DD/MM/YYYY"
                          disablePast
                          value={tripData.travelDate}
                          defauluvalue={d1}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(event, newValue) => {
                            console.log(event.$D);
                            console.log(event.$M + 1);
                            console.log(event.$y);
                            setTripData({ ...tripData, travelDate: event.$d });
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Box>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
       
      </Grid>
    </Grid>
  );
};

export default BookingSearch;
