import React, { useEffect } from "react";
import { Button, Grid, ThemeProvider } from "@mui/material";

import { theme } from "../../theme";

import { useDispatch, useSelector } from "react-redux";
import {
  getDataThunk,
  postDataThunk,
  setOrderNumber,
  setStatus,
  webSalesData,
  webSalesSettingsData,
  webSalesStatusesData,
  webSalesTicketsData,
  webSalesTripData,
} from "./web_salesSlice";

//components
import Navbar from "./components/Navbar";
import { MessagesHeader } from "./components/Headers";
import SummaryComponent from "./components/Summary";
import SearchComponent from "./components/Search";
import LoadingPage from "./components/LoadingPage";
import SelectComponent from "./components/Select";
import SearchReturnComponent from "./components/SearchReturn";
import BookingSummaryEmpty from "../../components/BookingSummaryEmpty";
import SelectTripsComponent from "./components/SelectTrips";
import SelectReturnComponent from "./components/SelectReturn";
import SelectTicketsComponent from "./components/selectTickets";
import SelectedTicketsSummaryComponent from "./components/SelectedTicketsSummary";

import background1 from "../../img/boat1111.jpeg";
import { Box } from "@mui/system";
import { allStrings } from "./stringsSlice";
import { allAppSetup } from "./appSetupSlice";
import SummaryEmptyComponent from "./components/SummaryEmpty";


import picture from '../../img/KRILO_ECLIPSE.png'

export default function WebSales() {
  const dispatch = useDispatch();
  const strings = useSelector(allStrings)
  const appSetup = useSelector(allAppSetup)
  const FatchData = async () => {
    const data = await dispatch(getDataThunk({ path: "web_data" })).unwrap();
  };
  const settingsData = useSelector(webSalesSettingsData);
  const statuses = useSelector(webSalesStatusesData);
  const tripData = useSelector(webSalesTripData);
  const tiketsData = useSelector(webSalesTicketsData);

  let canSelectTrips = false;

  useEffect(() => {
    if (!tripData.searchData.return_trip) {
      const checkStatus = [tripData.selected_trip.id != 0].every(Boolean);
      dispatch(setStatus({ path: "canSelectTrips", value: checkStatus }));
    } else {
      const checkStatus = [
        tripData.selected_trip.id !== 0,
        tripData.selected_return_trip.id !== 0,
      ].every(Boolean);
      dispatch(setStatus({ path: "canSelectTrips", value: checkStatus }));
    }
  });

  useEffect(() => {
    
    setInterval(() => {
      const orderNumber = tiketsData.orderNumber;
      if (orderNumber) {
        const data = {
          order_number:orderNumber
        }
        //dispatch(postDataThunk({ path: "check_payment", data: data }));
        if(tiketsData.invoice_data){
        window.location.replace('https://booking.tech4beez.com');
      }
      }
    }, 1000);
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        {settingsData.harbors ? (
          
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            
          >
            <Grid item xs={12}>
                <Navbar title= {strings.web_sales_title[appSetup.language]} />
            </Grid>

            <Grid item sm={11} md={8}>
              {settingsData.messages ? (
                <>
                  {settingsData.messages.map((m) => (
                    <MessagesHeader
                      key={m.id}
                      title={m.title}
                      text={m.text}
                      servety={m.servety}
                    />
                  ))}
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid item container direction="row" justifyContent="center" xs={11} md={11} lg={8}>
            
                {tiketsData.selectedTickets.length ? (
                  <Grid item
                    xs={true} xl={6} lg={8} md={8} sm={10}
                    sx={{ borderRadius: 3, boxShadow: 2, mt: 4, p: (1, -2, 2, 2) }}
                >
                  <SelectedTicketsSummaryComponent />
                  </Grid>
                ) : (
                  <Grid item 
                  xs={false} xl={6} lg={8} md={8} sm={10}
                  
                  sx={{ 
                    mt: 4, 
                    p: (2, 2, 2, 2), 
                    display: { xs: 'none', xl: 'block'}
                  }}
                  
                  >
                    <SummaryEmptyComponent/>
                  </Grid>
                )}
              <Grid
                item
                xs={true} xl={6} lg={8} md={8} sm={10}
                sx={{ borderRadius: 3, boxShadow: 2, mt: 4, p: (1, -2, 2, 2) }}
              >
                {!statuses.selectTicketType ? (
                  <>
                    <Grid item lg={12}>
                    <SearchComponent />
                    <SelectComponent />
                   

                    {statuses.canSelectTrips ? <SelectTripsComponent /> : ""}
                  </Grid>
                  </>
                ) : (
                  <SelectTicketsComponent />
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <LoadingPage />
        )}
      </ThemeProvider>
    </>
  );
}
