import {
  Grid,
  Autocomplete,
  TextField,
  Box,
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Title } from "./Headers";
import { useDispatch, useSelector } from "react-redux";
import {
  webSalesStatusesData,
  webSalesSettingsData,
  setSearchData,
  webSalesTripData,
  postDataThunk,
  webSalesTicketsData,
} from "../web_salesSlice";
import { useState } from "react";
import { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import updateLocale from "dayjs/plugin/updateLocale";
import { allStrings } from "../stringsSlice";
import { allAppSetup } from "../appSetupSlice";

import logo from "../../../img/LOGO.png";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

const SearchComponent = () => {
  const dispatch = useDispatch();
  const strings = useSelector(allStrings);
  const appSetup = useSelector(allAppSetup);
  const settingsData = useSelector(webSalesSettingsData);
  const initialMinute = 0;
  const initialSeconds = 30;
  const tripData = useSelector(webSalesTripData);
  const tiketsData = useSelector(webSalesTicketsData);

  const [firstDay, setFirstDay] = useState(new Date());

  let d = new Date(firstDay);
  const d1 = new Date(d);
  const d2 = new Date(d);
  const d3 = new Date(d);
  const d4 = new Date(d);
  const d5 = new Date(d);
  const d6 = new Date(d);
  d1.toLocaleDateString();
  d2.setDate(d.getDate() + 1);
  d2.toLocaleDateString();
  d3.setDate(d.getDate() + 2);
  d3.toLocaleDateString();
  d4.setDate(d.getDate() + 3);
  d4.toLocaleDateString();
  d5.setDate(d.getDate() + 4);
  d5.toLocaleDateString();
  d6.setDate(d.getDate() + 5);
  d6.toLocaleDateString();

  let days = {
    eng: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    cro: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
  };

  const getWeekDay = (d) => {
    let returnDay = "";
    days[appSetup.language].forEach((day, index) => {
      if (index == new Date(d).getDay()) {
        returnDay = day[0].toUpperCase() + day.slice(1);
      }
    });
    return returnDay;
  };

  const d2x = d2.getDate() + "." + (d2.getMonth() + 1);
  const d3x = d3.getDate() + "." + (d3.getMonth() + 1);
  const d4x = d4.getDate() + "." + (d4.getMonth() + 1);
  const d5x = d5.getDate() + "." + (d5.getMonth() + 1);

  const handleFirstDirectionData = (newData) => {
    const newValue = newData;
    dispatch(setSearchData({ path: "travel_from", value: newValue }));
  };

  let dtoswow = d1;
  const handleTravelDateData = (newData) => {
    //const nv = newData.toLocaleDateString()
    const nv = newData
    setFirstDay(nv)
    //dtoswow = nv
    const newValue = newData.toISOString();
    dispatch(
      setSearchData({ path: "travel_date", value: nv })
    );
  };

  const handleSecondDirectionData = (newData) => {
    const newValue = newData;
    dispatch(setSearchData({ path: "travel_to", value: newValue }));
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleChangeDate = (e, newValue) => {
    dispatch(
      setSearchData({ path: "travel_date", value: newValue })
    );
    if(newValue === d1.toISOString()){
      dtoswow = d1.toISOString()
    }
    else if(newValue === d2.toISOString()){
      dtoswow = d2.toISOString()
    }
    else if(newValue === d3.toISOString()){
      dtoswow = d3.toISOString()
    }
    else if(newValue === d4.toISOString()){
      dtoswow = d4.toISOString()
    }
     
  }
  
  const canSearch = [
    tripData.searchData.travel_from,
    tripData.searchData.travel_to,
    tripData.searchData.travel_date,
  ].every(Boolean);

  useEffect(() => {
    if (canSearch) {
      dispatch(
        postDataThunk({ path: "search_trip", data: tripData.searchData })
      ).unwrap();
    }
  }, [tripData.searchData]);

  return (
    <Grid>
      <Grid item lg={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Grid item>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  height="75px"
                  src={logo}
                  style={{ cursor: "pointer", borderRadius: "5%" }}
                />
              </Box>
              {tiketsData.selectedTickets.length ? (
                <Title
                  subtitle={strings.search_title_additional[appSetup.language]}
                />
              ) : (
                <Title
                  subtitle={strings.search_title_empty[appSetup.language]}
                />
              )}
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  disablePortal
                  id="travel_from"
                  options={settingsData.harbors}
                  fullwidth="true"
                  //value={tripData.travelFrom}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={strings.travel_from[appSetup.language]}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleFirstDirectionData(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disablePortal
                  id="travel_to"
                  options={settingsData.harbors}
                  fullwidth="true"
                  //value={tripData.travelTo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={strings.travel_to[appSetup.language]}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleSecondDirectionData(newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item container direction="row" spacing={1} mb={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <ToggleButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    fullWidth
                    value={tripData.searchData.travel_date}
                    onChange={handleChangeDate}
                    color="primary"
                    exclusive
                    sx={{
                      height: 57,
                    }}
                  >
                    <ToggleButton value={d1.toISOString()} aria-label="d1">
                      <Stack direction="column">
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {getWeekDay(d1)}
                        </Typography>
                        <Typography>{new Date(d1).getDate()}</Typography>
                      </Stack>
                    </ToggleButton>
                    <ToggleButton value={d2.toISOString()} aria-label="d2">
                      <Stack direction="column">
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {getWeekDay(d2)}
                        </Typography>
                        <Typography>{new Date(d2).getDate()}</Typography>
                      </Stack>
                    </ToggleButton>
                    <ToggleButton value={d3.toISOString()} aria-label="d3">
                      <Stack direction="column">
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {getWeekDay(d3)}
                        </Typography>
                        <Typography>{new Date(d3).getDate()}</Typography>
                      </Stack>
                    </ToggleButton>
                    <ToggleButton value={d4.toISOString()} aria-label="d4">
                      <Stack direction="column">
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {getWeekDay(d4)}
                        </Typography>
                        <Typography>{new Date(d4).getDate()}</Typography>
                      </Stack>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label={strings.travel_date[appSetup.language]}
                          inputFormat="DD/MM/YYYY"
                          disablePast
                          sx={{ height: 100 }}
                          slotProps={{ textField: { size: "small" } }}
                          value={tripData.searchData.travel_date}
                          renderInput={(params) => (
                            <TextField size="medium" {...params} />
                          )}
                          onChange={(event, newValue) => {
                            console.log(event.$D);
                            console.log(event.$M + 1);
                            console.log(event.$y);
                            handleTravelDateData(event.$d);
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchComponent;
