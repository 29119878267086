import {
  Menu,
  Home,
  CurrencyExchange,
  HelpOutline,
  AccountBalanceWallet,
  ContentCopy,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  styled,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import { HeaderSmall } from "./Headers";
import { allAppSetup, changeLanguage } from "../appSetupSlice";
import { useDispatch, useSelector } from "react-redux";
import { allStrings } from "../stringsSlice";


const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const Navbar = ({ title }) => {
  const dispatch = useDispatch();
  const strings = useSelector(allStrings);
  const appSetup = useSelector(allAppSetup);
  const [state, setState] = React.useState(false);

  const handleOpenDrawer = () => {
    setState(true);
  };
  const handleCloseDrawer = () => {
    setState(false);
  };

  const handleChangeLanguage = () => {
    if (appSetup.language === "cro") {
      dispatch(changeLanguage("eng"));
    } else {
      dispatch(changeLanguage("cro"));
    }
  };

  return (
    <AppBar position="sticky">
      <StyledToolbar>
        <Icons>
          <Menu onClick={handleOpenDrawer}  sx={{ color:'white' }}></Menu>

          {state ? (
            <Drawer
              md={8}
              anchor="top"
              open={state}
              onClose={handleCloseDrawer}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton href={strings.home_link[appSetup.language]} target="_blank">
                    <ListItemIcon>
                      <Home />
                    </ListItemIcon>
                    <ListItemText primary={strings.home_title[appSetup.language]} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    href={strings.refund_link[appSetup.language]}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <CurrencyExchange />
                    </ListItemIcon>
                    <ListItemText primary={strings.refund_title[appSetup.language]} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    href={strings.faq_link[appSetup.language]}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <HelpOutline />
                    </ListItemIcon>
                    <ListItemText primary={strings.faq_title[appSetup.language]} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    href={strings.payment_link[appSetup.language]}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <AccountBalanceWallet />
                    </ListItemIcon>
                    <ListItemText primary={strings.payment_title[appSetup.language]} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    href={strings.terms_link[appSetup.language]}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <ContentCopy />
                    </ListItemIcon>
                    <ListItemText primary={strings.terms_title[appSetup.language]} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          ) : (
            ""
          )}
        </Icons>
          <HeaderSmall title={title} />
        
        <IconButton onClick={handleChangeLanguage}>
          {appSetup.language === "cro" ? (
            <Typography sx={{ fontWeight: 'bold' , color:'white'}}>English</Typography>
          ) : (
            <Typography sx={{ fontWeight: 'bold', color:'white' }}>Hrvatski</Typography>
          )}
        </IconButton>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
