import {configureStore } from "@reduxjs/toolkit"
import bookingSlice from "../pages/sales/bookingSlice"
import appSetupSlice from "../pages/web_sales/appSetupSlice"
import stringsSlice from "../pages/web_sales/stringsSlice"
import web_salesSlice from "../pages/web_sales/web_salesSlice"



export const store = configureStore({
    reducer:{
        booking: bookingSlice,
        appSetup: appSetupSlice,
        web_sales: web_salesSlice,
        strings: stringsSlice
    }
})