import { createTheme } from "@mui/material";
import image from "./pic/brod.png"

export const theme = createTheme({
  
  backgroundImage:`url(${image})`,
  
  palette:{
    primary:{
      main:"#42aaf5",
      light: "skyblue"
    },
    secondary:{
      main:"#15c630",
    },
    otherColor:{
      main:"#999"
    },
   select: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'red',
      },
    },
  }
})