import React from "react";
import { v4 as uuid } from "uuid";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { HeaderSmall } from "./Headers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postDataThunk,
  resetTicketsData,
  resetTripData,
  setBuyerData,
  setOrderNumber,
  setPreparedTickets,
  setStatus,
  updateTickets,
  webSalesStatusesData,
  webSalesTicketsData,
} from "../web_salesSlice";
import { MonriPaymnet } from "../../sales/monri";
import { allStrings } from "../stringsSlice";
import { allAppSetup } from "../appSetupSlice";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import mc from "../../../img/mc_vrt_opt_pos_63_2x.png";
import ms from "../../../img/ms_vrt_opt_pos_53_2x.png";
import visa from "../../../img/Visa 2015 50.gif";
import diners from "../../../img/Diners50.gif";
import amex from "../../../img/AmericanExpress50.jpg";

function subtotal(tickets) {
  return tickets
    .map(({ total_price }) => total_price)
    .reduce((sum, i) => sum + i, 0);
}

const SelectedTicketsSummaryComponent = () => {
  const strings = useSelector(allStrings);
  const appSetup = useSelector(allAppSetup);
  const ticketsData = useSelector(webSalesTicketsData);
  const statuses = useSelector(webSalesStatusesData);
  const initialMinute = 14;
  const initialSeconds = 59;
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const dispatch = useDispatch();

  const handleTimeOut = () =>{
    dispatch(resetTripData())
    dispatch(resetTicketsData())
  }

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          handleTimeOut();
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const preparTickets = () => {
    let preparedTickets = [];
    const uniqueSalesRoute = ticketsData.selectedTickets.filter(
      (v, i, a) =>
        a.findIndex((t) => t.sales_route_uuid === v.sales_route_uuid) === i
    );
    for (const salesRoute of uniqueSalesRoute) {
      const ticeketsForRoute = ticketsData.selectedTickets.filter(
        (ticket) => ticket.sales_route_uuid === salesRoute.sales_route_uuid
      );
      const uniqueTicketType = ticeketsForRoute.filter(
        (v, i, a) =>
          a.findIndex((t) => t.ticket_type_uuid === v.ticket_type_uuid) === i
      );
      let ticketsByType = [];
      for (const ticketType of uniqueTicketType) {
        const ticketsForType = ticeketsForRoute.filter(
          (ticket) => ticket.ticket_type_uuid === ticketType.ticket_type_uuid
        );
        const subtotalSinglePrice = ticketsForType
          .map(({ single_price }) => single_price)
          .reduce((sum, i) => sum + i, 0);
        const subtotalTotalPrice = ticketsForType
          .map(({ total_price }) => total_price)
          .reduce((sum, i) => sum + i, 0);
        const subtotalVAtBAse = ticketsForType
          .map(({ total_vat_base }) => total_vat_base)
          .reduce((sum, i) => sum + i, 0);
        const subtotalVat = ticketsForType
          .map(({ total_vat }) => total_vat)
          .reduce((sum, i) => sum + i, 0);
        const subtotalHarborTax = ticketsForType
          .map(({ total_harbor_tax }) => total_harbor_tax)
          .reduce((sum, i) => sum + i, 0);
        const subtotalQuantity = ticketsForType
          .map(({ quantity }) => quantity)
          .reduce((sum, i) => sum + i, 0);
        const addTicketByType = {
          ticket_type_name: ticketType.ticket_type_name,
          ticket_type_id: ticketType.ticket_type_id,
          ticket_type_uuid: ticketType.ticket_type_uuid,
          single_price: ticketType.single_price,
          total_price: subtotalTotalPrice,
          total_vat_base: subtotalVAtBAse,
          total_vat: subtotalVat,
          total_harbor_tax: subtotalHarborTax,
          quantity: subtotalQuantity,
        };
        ticketsByType = [...ticketsByType, addTicketByType];
      }
      const ticketItemPrice = ticketsByType
        .map(({ total_price }) => total_price)
        .reduce((sum, i) => sum + i, 0);
      const ticketItemVatBase = ticketsByType
        .map(({ total_vat_base }) => total_vat_base)
        .reduce((sum, i) => sum + i, 0);
      const ticketItemVat = ticketsByType
        .map(({ total_vat_base }) => total_vat_base)
        .reduce((sum, i) => sum + i, 0);
      const ticketItemHarborTax = ticketsByType
        .map(({ total_harbor_tax }) => total_harbor_tax)
        .reduce((sum, i) => sum + i, 0);

      const addTicketsGroup = {
        sales_route_uuid: salesRoute.sales_route_uuid,
        line_code: salesRoute.line_code,
        line_name: salesRoute.line_name,
        departure: salesRoute.departure,
        departure_harbor_id: salesRoute.departure_harbor_id,
        departure_harbor_name: salesRoute.departure_harbor_name,
        arrival: salesRoute.arrival,
        arrival_harbor_id: salesRoute.arrival_harbor_id,
        arrival_harbor_name: salesRoute.arrival_harbor_name,
        ticket_item_price: ticketItemPrice,
        ticket_item_vat_base: ticketItemVatBase,
        ticket_item_vat: ticketItemVat,
        ticket_item_harbor_tax: ticketItemHarborTax,
        ticketsData: ticketsByType,
      };
      preparedTickets = [...preparedTickets, addTicketsGroup];
    }
    dispatch(setPreparedTickets({ value: preparedTickets }));
    };

  useEffect(() => {
    preparTickets();
  }, [ticketsData.selectedTickets]);

  const invoiceSubtotal = subtotal(ticketsData.selectedTickets);

  const handleRemove = (e, row) => {
    const filteredTickets = ticketsData.selectedTickets.filter(
      (ticket) => ticket.sales_route_uuid !== row.sales_route_uuid
    );
    dispatch(updateTickets({ value: filteredTickets }));
  };

  const handleBuyerData = (e) => {
    dispatch(setBuyerData({ path: e.target.name, value: e.target.value }));
  };

  const handleCheck = (e) => {
    dispatch(
      setBuyerData({ path: "summary_checkbox_term", value: e.target.checked })
    );
  };

  const handleVaidateEmail = () => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (
      ticketsData.buyerData.summary_buyer_email &&
      ticketsData.buyerData.summary_buyer_email.match(isValidEmail) &&
      ticketsData.buyerData.summary_buyer_name &&
      ticketsData.buyerData.summary_checkbox_term
    ) {
      dispatch(setStatus({ path: "validateBuyerData", value: true }));
    } else {
      dispatch(setStatus({ path: "validateBuyerData", value: false }));
    }
  };

  const [companyInvoice, setcompanyInvoice] = useState(false);

  const handleChangeCompanyInvoice = (event) => {
    setcompanyInvoice(event.target.checked);
  };

  const handleSubmit = async () => {
    const uuidOrder = uuid();

    //dispatch(setOrderNumber({value:uuidOrder}))
    await dispatch(
      postDataThunk({
        path: "order_cofirmation",
        data: {
          data: ticketsData.preparedTickets,
          order_number: uuidOrder,
          buyer_data: ticketsData.buyerData,
        },
      })
    );
    //setShowFinish(true);
    const intAmount = invoiceSubtotal * 100;
    const value = {
      order_number: uuidOrder,
      amount: intAmount,
      order_info: uuidOrder,
    };
    console.log(value);
    setMinutes(0);
    setSeconds(15);
    MonriPaymnet(value);
  };

  useEffect(() => {
    handleVaidateEmail();
  }, [ticketsData.buyerData]);

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Grid item>
            {minutes === 0 && seconds === 0 ? (
              ""
            ) : (
              <h2>
                {" "}
                {strings.summary_time[appSetup.language]} {minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </h2>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item>
            <HeaderSmall subtitle={strings.summary_title[appSetup.language]} subtitle1={strings.summary_subtitle[appSetup.language]}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {ticketsData.preparedTickets ? (
            <>
              {ticketsData.preparedTickets.map((row) => (
                <Box
                  key={row.sales_route_uuid}
                  alignItems="flex-end"
                  fullwidth="true"
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 7,
                    borderRadius: 2,
                    mb: 2,
                    p: (0, 2, 2, 2),
                  }}
                >
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                        <ShoppingCartOutlinedIcon/>

                      <Button
                        color="error"
                        onClick={(e) => handleRemove(e, row)}
                      >
                        {strings.summary_remove_ticket[appSetup.language]}
                      </Button>
                    </Box>
                    <Grid>
                      <h3>
                        {row.departure_harbor_name.toUpperCase()} -{" "}
                        {row.arrival_harbor_name.toUpperCase()}
                      </h3>
                    </Grid>
                    <Grid>
                      <h4>
                        {strings.summary_departure[appSetup.language]}:{" "}
                        {row.departure}
                      </h4>
                    </Grid>

                    <Grid alignItems="flex-end">
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {strings.summary_ticket_type[appSetup.language]}
                              </TableCell>
                              <TableCell align="right">
                                {strings.summary_quantity[appSetup.language]}
                              </TableCell>
                              <TableCell align="right"
                                 sx={{
                                  display: { xs: 'none', sm:'block'}
                                }}
                              >
                                {strings.summary_price[appSetup.language]}
                              </TableCell>
                              <TableCell align="right">
                                {strings.summary_amount[appSetup.language]}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.ticketsData.map((ticket) => (
                              <TableRow
                                key={ticket.ticket_group_uuid}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {ticket.ticket_type_name}
                                </TableCell>
                                <TableCell align="right">
                                  {ticket.quantity}
                                </TableCell>
                                <TableCell align="right"
                                   sx={{
                                    display: { xs: 'none', sm:'block'}
                                  }}
                                >
                                  {ticket.single_price.toFixed(2)} EUR
                                </TableCell>
                                <TableCell align="right">
                                  {ticket.total_price.toFixed(2)} EUR
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                </Box>
              ))}
            </>
          ) : (
            ""
          )}
          <Box
            sx={{
              width: "100%",
              mt: 2,
            }}
          >
            <Box>
              <Stack direction="row">
                <HeaderSmall
                  subtitle={strings.summary_buyer_title[appSetup.language]}
                />
                <Tooltip title={strings.summary_buyer_info[appSetup.language]}>
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack direction="row" justifyContent="center" sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    width: "75vw",
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    
                        <Switch
                          color="primary"
                          checked={companyInvoice}
                          onChange={handleChangeCompanyInvoice}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            mb: 1,
                          }}
                        />
              
                    <Typography>{strings.summary_buyer_company_invoice[appSetup.language]}</Typography>
                  </Stack>
                  <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    label={strings.summary_buyer_name[appSetup.language]}
                    placeholder={strings.summary_buyer_name[appSetup.language]}
                    required
                    value={ticketsData.buyerData.summary_buyer_name || ""}
                    onChange={handleBuyerData}
                    name="summary_buyer_name"
                    sx={{ gridColumn: "span 4", mb: 2 }}
                  />
                  <TextField
                    type="email"
                    variant="outlined"
                    fullWidth
                    label={strings.summary_buyer_email[appSetup.language]}
                    placeholder={strings.summary_buyer_email[appSetup.language]}
                    required
                    value={ticketsData.buyerData.summary_buyer_email || ""}
                    onChange={handleBuyerData}
                    name="summary_buyer_email"
                    sx={{ gridColumn: "span 4", mb: 2 }}
                  />

                  {companyInvoice ? (
                    <>
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        label={
                          strings.summary_buyer_company_name[appSetup.language]
                        }
                        placeholder={
                          strings.summary_buyer_company_name[appSetup.language]
                        }
                        value={
                          ticketsData.buyerData.summary_buyer_company_name || ""
                        }
                        onChange={handleBuyerData}
                        name="summary_buyer_company_name"
                        sx={{ gridColumn: "span 4", mb: 2 }}
                      />
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        label={
                          strings.summary_buyer_company_address[
                            appSetup.language
                          ]
                        }
                        placeholder={
                          strings.summary_buyer_company_address[
                            appSetup.language
                          ]
                        }
                        value={
                          ticketsData.buyerData.summary_buyer_company_address ||
                          ""
                        }
                        onChange={handleBuyerData}
                        name="summary_buyer_company_address"
                        sx={{ gridColumn: "span 4", mb: 2 }}
                      />
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        label={
                          strings.summary_buyer_company_vat_id[
                            appSetup.language
                          ]
                        }
                        placeholder={
                          strings.summary_buyer_company_vat_id[
                            appSetup.language
                          ]
                        }
                        value={
                          ticketsData.buyerData.summary_buyer_company_vat_id ||
                          ""
                        }
                        onChange={handleBuyerData}
                        name="summary_buyer_company_vat_id"
                        sx={{ gridColumn: "span 4", mb: 2 }}
                      />
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        label={
                          strings.summary_buyer_company_country[
                            appSetup.language
                          ]
                        }
                        placeholder={
                          strings.summary_buyer_company_country[
                            appSetup.language
                          ]
                        }
                        value={
                          ticketsData.buyerData.summary_buyer_company_country ||
                          ""
                        }
                        onChange={handleBuyerData}
                        name="summary_buyer_company_country"
                        sx={{ gridColumn: "span 4", mb: 2 }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  checked={ticketsData.buyerData.summary_checkbox_term}
                  onChange={handleCheck}
                />
                  <Typography >
                    {strings.summary_checkbox_term_first[appSetup.language]} <Link href={strings.terms_link[appSetup.language]} underline="none" target="_blank">{strings.summary_checkbox_term_general[appSetup.language]}</Link> <Link href={strings.payment_link[appSetup.language]} underline="none" target="_blank">{strings.summary_checkbox_term_payment[appSetup.language]}</Link>
                  </Typography >
              </Stack>
            </Box>
          </Box>
          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              fullWidth
              disabled={!statuses.validateBuyerData}
              sx={{
                height: 60,
              }}
              onClick={handleSubmit}
            >
              <h2>
                {strings.summary_pay[appSetup.language]}{" "}
                {invoiceSubtotal.toFixed(2)} EUR
              </h2>
            </Button>
          </Grid>
          <Grid item xs={12} mt={2} container direction="row" spacing={2} justifyContent="center"
              alignItems="center">
           
           <Grid item>

              <Button onClick={() => window.open('https://www.mastercard.hr/hr-hr.html', '_blank')} >                
                  <img
                    alt="mastercard"
                    height="50px"
                    src={mc}
                    style={{ cursor: "pointer"}}
                  />
                </Button>
           </Grid>
           <Grid item>
              <Button onClick={() => window.open('https://www.mastercard.com/brandcenter/en/home', '_blank')} >                
                  <img
                    alt="maestro"
                    height="50px"
                    src={ms}
                    style={{ cursor: "pointer"}}
                  />
                </Button>

           </Grid>
           <Grid item>
              <Button onClick={() => window.open('https://www.visa.co.uk/about-visa/visa-in-europe.html', '_blank')} >                
                  <img
                    alt="visa"
                    height="50px"
                    src={visa}
                    style={{ cursor: "pointer"}}
                  />
                </Button>
           </Grid>
           
           <Grid item>
              <Button onClick={() => window.open('https://www.americanexpress.com', '_blank')} >                
                  <img
                    alt="amex"
                    height="50px"
                    src={amex}
                    style={{ cursor: "pointer"}}
                  />
                </Button>
           </Grid>
           </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectedTicketsSummaryComponent;
