import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Menu, Home, CurrencyExchange, HelpOutline, AccountBalanceWallet, ContentCopy} from '@mui/icons-material';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { HeaderSmall } from './Header';

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between"

});


const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const Navbar = ({ title }) => {

  const [state, setState] = React.useState(false);

  const handleOpenDrawer = () => {
    setState(true)
  }
  const handleCloseDrawer = () => {
    setState(false)
  }

  return (
    <AppBar position="sticky">
      <StyledToolbar>
        <Icons>
          <Menu
            onClick={handleOpenDrawer}>

          </Menu>

          {state ? <Drawer md={8}
            anchor='top'
            open={state}
            onClose={handleCloseDrawer}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton href="http://www.krilo.hr" target="_blank">
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="https://krilo.aktiva-info.hr/eng/frmKoraciZaPovratEng.aspx" target="_blank">
                  <ListItemIcon>
                    <CurrencyExchange />
                  </ListItemIcon>
                  <ListItemText primary="Refund informations" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="https://krilo.aktiva-info.hr/eng/frmFAQEng.aspx" target="_blank">
                  <ListItemIcon>
                    <HelpOutline />
                  </ListItemIcon>
                  <ListItemText primary="FAQ" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="https://krilo.aktiva-info.hr/eng/frmUvjetiKupnjeEng.aspx" target="_blank">
                  <ListItemIcon>
                    <AccountBalanceWallet />
                  </ListItemIcon>
                  <ListItemText primary="Payment and privacy statement" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="https://krilo.aktiva-info.hr/eng/frmUvjetiKupnjeEng.aspx" target="_blank">
                  <ListItemIcon>
                    <ContentCopy />
                  </ListItemIcon>
                  <ListItemText primary="Terms and conditions" />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer> : ''}
        </Icons>
        <HeaderSmall
          title={title}  
        /> 
        <Typography>
          Hrvatski
        </Typography>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;