import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {url} from "../../config/config"

const initialState = {
    statuses:{
        language:'eng',
        showSummary:true,
        canSelectTrips:false,
        canSelectReturnTrips:false,
        selectTicketType: false,
        canSelectTickets: false,
        validateBuyerData: false
    },
    settings_data:{
        messages:[],
        strings:{
            summary_page: {},
        }
    },
    tripsData:{
        searchData:{
          travel_date:'',
          return_travel_date:'',
          return_trip: false
        },
        selectedData:{
          selected_trip:{
            id:0
          }
        },
        selected_trip:{
          id:0
        },
        selected_return_trip:{
          id:0
        },
        ticketsCounter:[],
    },
    ticketsData:{
      selectedTickets:[],
      preparedTickets:[],
      buyerData:{},
      orderNumber:''
    },
    orderData:{
      tickets:{}
    },
    pricelistData:[
      {
        pricelist_id:'',
        pricelist_uuid:'',
        pricelist_name:'',
        harborFirst:'',
        harborSecond:'',
        prices:[
          {
            ticket_type_id:'',
            ticket_type_uuid:'',   
            ticket_type_name:'',   
            price:'',
            vat_base:'',
            vat:'',
            harbor_tax:'',
            description:'',
          }
        ]
      },
    ]
};

export const getDataThunk = createAsyncThunk(
  "bookingweb/getDataThunk",
  async (arg, { getState }) => {
    const path = arg.path;
    const response = await axios.get(url + "/web_sales/" + path);
    return response.data;
  }
);

export const postDataThunk = createAsyncThunk(
  "bookingweb/postDataThunk",
  async (arg, { getState }) => {
    console.log(arg);
    const data = arg.data;
    const path = arg.path;
    try {
      const response = await axios.post(url + "/web_sales/" + path, data);
      return response.data;
    } catch (error) {}
  }
);

const web_salesSlice = createSlice({
  name: "web_sales",
  initialState,
  reducers: {
    setStatus:(state,action) =>{
        state.statuses[action.payload.path] = action.payload.value
    },
    setSearchData: (state, action) => {
        console.log(action.payload)
        state.tripsData.searchData[action.payload.path] = action.payload.value
    },
    setSelectedTripData: (state, action) => {
      state.tripsData[action.payload.path] = action.payload.value
    },
    resetTripData:(state)=>{
      state.tripsData = initialState.tripsData
    },
    resetTicketsData:(state)=>{
      state.ticketsData = initialState.ticketsData
    },
    setTicketsCounter: (state, action) =>{
      console.log(action.payload)
      state.tripsData.ticketsCounter = action.payload.value
    },
    updateTicketsCounter: (state, action) =>{
      console.log(action.payload)
      const {path, value} = action.payload
      let counter = state.tripsData.ticketsCounter.find((counter) => counter.id === path)
      if(counter){
        counter.id= value.id
        counter.quantity = value.quantity
        counter.data= value.data
      }
    },
    setTickets: (state, action) =>{
      state.ticketsData.selectedTickets = [...state.ticketsData.selectedTickets, action.payload.value]
    },
    updateTickets: (state, action) =>{
      state.ticketsData.selectedTickets = action.payload.value
    },
    setBuyerData: (state, action) =>{
      state.ticketsData.buyerData[action.payload.path] = action.payload.value
    },
    setOrderNumber: (state, action) =>{
      state.ticketsData.orderNumber = action.payload.value
    },
    setPreparedTickets: (state, action) =>{
      state.ticketsData.preparedTickets = action.payload.value
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getDataThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDataThunk.fulfilled, (state, action) => {
        state.status = "completed";
        console.log(action.payload)
        state[action.payload.path]= action.payload.data;
      })
      .addCase(getDataThunk.rejected, (state, action) => {
        state.status = "failed";
        //state.error.message = action.payload.message
      })
      .addCase(postDataThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postDataThunk.fulfilled, (state, action) => {
        state.status = "completed";
        console.log(action.payload)
        state[action.payload.path][action.payload.path2] = action.payload.data;
      })
      .addCase(postDataThunk.rejected, (state, action) => {
        state.status = "failed";
        //state.error.message = action.payload.message
      });
  },
});

export const {
    setStatus,
    setSearchData,
    setSelectedTripData,
    resetTripData,
    resetTicketsData,
    setTicketsCounter,
    updateTicketsCounter,
    setTickets,
    updateTickets,
    setBuyerData,
    setOrderNumber,
    setPreparedTickets
} = web_salesSlice.actions

export const webSalesData = (state) => state.web_sales
export const webSalesSettingsData = (state) => state.web_sales.settings_data
export const webSalesStatusesData = (state) => state.web_sales.statuses
export const webSalesStingsSummaryPage = (state) => state.web_sales.settings_data.strings.summary_page
export const webSalesTripData = (state) => state.web_sales.tripsData
export const webSalesTicketsData = (state) => state.web_sales.ticketsData

export default web_salesSlice.reducer