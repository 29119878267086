import { Button, Grid,Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { allAppSetup } from "../appSetupSlice";
import { allStrings } from "../stringsSlice";
import { setStatus, webSalesTripData } from "../web_salesSlice";

const SelectTripsComponent = () => {
  const dispatch = useDispatch();
  const strings = useSelector(allStrings)
  const appSetup = useSelector(allAppSetup)
 


  const handleSelectTrips = () => {
    dispatch(setStatus({ path: "selectTicketType", value: true }));
  };

  
  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={2} mt={2}>
         <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: 60,
            }}
            onClick={handleSelectTrips}
          >
            <Typography color='white' variant="h5"> {strings.select_trips[appSetup.language]}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectTripsComponent;
