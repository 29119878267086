import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
//const URL = 'http://booking-backend.tech4beez.com';
const URL = 'http://localhost:5000'; 
const initialState = {
    invNo: 5000,
    data:{
        
    },
    tripData:{},
    trip:{
        id:''
    }
}

export const getDataThunk = createAsyncThunk('bookingweb/getDataThunk', async(arg,{getState})=>{
    const state = getState()
    const path = arg.path
    const response = await axios.get(URL + '/web_sales/'+ path)
    return response.data
})

export const postDataThunk = createAsyncThunk('bookingweb/postDataThunk', async(arg,{getState})=>{
    console.log(arg)
    const state = getState()
    const data = arg.data
    const path = arg.path
    try {
        const response = await axios.post(URL + '/web_sales/' + path, data)
        return response.data
    } catch (error) {
        
    }
})

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers:{
        setReturnTrip:(state, action)=>{
            state.returnTrip = action.payload
        },
        setTripsData:(state, action)=>{
           
        },
        setBasket:(state, action)=>{

        },
        setAddedTrips:(state, action)=>{
            console.log(action.payload)
            state.addedTickets = action.payload
        },
        setInvNo:(state, action)=>{
            state.invNo ++
        }
    },
    extraReducers(builder){
        builder
            .addCase(getDataThunk.pending,(state, action)=>{
                state.status ='loading'
            })
            .addCase(getDataThunk.fulfilled,(state, action)=>{
                state.status = 'completed'
                state[action.payload.path] = action.payload.data
            })
            .addCase(getDataThunk.rejected,(state, action)=>{
                state.status = 'failed'
                //state.error.message = action.payload.message
            })
            .addCase(postDataThunk.pending,(state, action)=>{
                state.status ='loading'
            })
            .addCase(postDataThunk.fulfilled,(state, action)=>{
                state.status = 'completed'
                state[action.payload.path] = action.payload.data
            })
            .addCase(postDataThunk.rejected,(state, action)=>{
                state.status = 'failed'
                //state.error.message = action.payload.message
            })
    }
})

export const {
    setReturnTrip,
    setAddedTrips
} = bookingSlice.actions

export const allData = (state) => state.booking
export const priceForTrips = (state, action) =>{
    const harborFrom = 'HR001'
    const harborTo = 'HR201'
    const validFrom = '03.09.2023'
    const validTo = '06.09.2023'
    let prices = state.booking.data.trip_prices.find((prices) => 
        prices.departure_harbor_id === harborFrom )
        return prices
}

export default bookingSlice.reducer