import {
  ButtonGroup,
  Button,
  Grid,
  Paper,
  Autocomplete,
  TextField,
  Box,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import { Title } from "./Headers";
import { useDispatch, useSelector } from "react-redux";
import {
  webSalesStingsSummaryPage,
  webSalesStatusesData,
  webSalesSettingsData,
  setSearchData,
  webSalesTripData,
  postDataThunk,
} from "../web_salesSlice";
import { useState } from "react";
import { useEffect } from "react";
import {
  DesktopDatePicker,
  MobileDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

const SearchReturnComponent = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector(webSalesSettingsData);
  const initialMinute = 0;
  const initialSeconds = 30;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const strings = useSelector(webSalesStingsSummaryPage);
  const statuses = useSelector(webSalesStatusesData);
  const tripData = useSelector(webSalesTripData);

  const d1 = new Date(tripData.searchData.travel_date);
  const d2 = new Date(d1);
  const d3 = new Date(d1);
  const d4 = new Date(d1);
  const d5 = new Date(d1);
  const d6 = new Date(d1);
  d2.setDate(d1.getDate() + 1);
  d3.toLocaleDateString();
  d3.setDate(d1.getDate() + 2);
  d3.toLocaleDateString();
  d4.setDate(d1.getDate() + 3);
  d4.toLocaleDateString();
  d5.setDate(d1.getDate() + 4);
  d5.toLocaleDateString();
  d6.setDate(d1.getDate() + 5);
  d6.toLocaleDateString();

  let dtoswow = d2  
  let days = {
    eng: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    cro: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
  };
  const len = "eng";

  const getWeekDay = (d) => {
    let returnDay = "";
    days[len].forEach((day, index) => {
      if (index == new Date(d).getDay()) {
        returnDay = day;
      }
    });
    return returnDay;
  };

  const d2x = d2.getDate() + "." + (d2.getMonth() + 1);
  const d3x = d3.getDate() + "." + (d3.getMonth() + 1);
  const d4x = d4.getDate() + "." + (d4.getMonth() + 1);
  const d5x = d5.getDate() + "." + (d5.getMonth() + 1);

  const handleDateD2 = () => {
    dispatch(
      setSearchData({ path: "return_travel_date", value: d2.toISOString() })
    );
  };
  const handleDateD3 = () => {
    dispatch(
      setSearchData({ path: "return_travel_date", value: d3.toISOString() })
    );
  };
  const handleDateD4 = () => {
    dispatch(
      setSearchData({ path: "return_travel_date", value: d4.toISOString() })
    );
  };
  const handleDateD5 = () => {
    dispatch(
      setSearchData({ path: "return_travel_date", value: d5.toISOString() })
    );
  };

  const handleTravelDateData = (newData) => {
    console.log(newData);
    const newValue = newData;
    dispatch(
      setSearchData({
        path: "return_travel_date",
        value: newValue.toISOString(),
      })
    );
  };

  const canSearchReturn = [
    tripData.searchData.travel_from,
    tripData.searchData.travel_to,
    tripData.searchData.travel_date,
    tripData.trips,
  ].every(Boolean);

  useEffect(() => {
    console.log("can search return");
    console.log(canSearchReturn);
    if (canSearchReturn) {
      dispatch(
        postDataThunk({ path: "search_return_trip", data: tripData.searchData })
      ).unwrap();
    }
  }, [tripData.searchData]);

  const handleChangeDate = (e, newValue) => {
    console.log(newValue)
    console.log(d1)
    dispatch(
      setSearchData({ path: "return_travel_date", value: newValue })
    );
    if(newValue === d2.toISOString()){
      console.log('tu smo D2 ')
      dtoswow = d2.toISOString()
    }
    else if(newValue === d3.toISOString()){
      console.log('tu smo D3 ')
      console.log(d3.toISOString())
      dtoswow = d3.toISOString()
    }
    else if(newValue === d4.toISOString()){
      console.log('tu smo D4 ')
      dtoswow = d4.toISOString()
    }
    else if(newValue === d5.toISOString()){
      console.log('tu smo D5 ')
      dtoswow = d5.toISOString()
    }
     
  }

  return (
    <Grid>
      <Grid item lg={12} sx={{ mt: 4 }}>
        <Grid item container direction="row" spacing={1} mb={2}>
          <Grid item xs={12} sm={12} md={8}>
            <ToggleButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              fullWidth
              value={tripData.searchData.travel_date}
              onChange={handleChangeDate}
              color="primary"
              exclusive
              sx={{
                height:57
              }}
            >
              <ToggleButton value={d1.toISOString()} aria-label="d1">
                <Stack direction="column">
                  <Typography>{getWeekDay(d1)}</Typography>
                  <Typography>{new Date(d1).getDate()}</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={d2.toISOString()} aria-label="d2">
                <Stack direction="column">
                  <Typography>{getWeekDay(d2)}</Typography>
                  <Typography>{new Date(d2).getDate()}</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={d3.toISOString()} aria-label="d3">
                <Stack direction="column">
                  <Typography>{getWeekDay(d3)}</Typography>
                  <Typography>{new Date(d3).getDate()}</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={d4.toISOString()} aria-label="d4">
                <Stack direction="column">
                  <Typography>{getWeekDay(d4)}</Typography>
                  <Typography>{new Date(d4).getDate()}</Typography>
                </Stack>
              </ToggleButton>
              
              
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    label="Return date"
                    inputFormat="DD/MM/YYYY"
                    disablePast
                    value={tripData.searchData.return_travel_date}
                    closeOnSelect={true}
                    minDate={tripData.searchData.travel_date}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      console.log(event.$D);
                      console.log(event.$M + 1);
                      console.log(event.$y);
                      handleTravelDateData(event.$d);
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchReturnComponent;
